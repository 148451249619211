import React, { useEffect, useRef } from 'react'

// import icons
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';

// import scss
import '../../assets/scss/components/account/modalVerifyEmail.scss'

const ModalVerifyEmail = ({closeVerifyEmailModal}) => {
    const modalRef = useRef(null);

    // Function to handle clicks outside the modal
    function handleOutsideModalClick(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // Click occurred outside the modal
        handleModalClose()
      }
    }

    const handleModalClose = () => {
        closeVerifyEmailModal()
    }
  
    // Add a click event listener when the component mounts
    useEffect(() => {
      document.addEventListener('click', handleOutsideModalClick);
  
      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('click', handleOutsideModalClick);
      };
    }, []);

    return (
        <div 
            id="unverified-email-modal" 
            className='modal modal-verify-email p-2 br-10'
            ref={modalRef}
        >
            <WarningOutlinedIcon className="warning-icon mb-1" fontSize='large' />
            <p className="warning-message mb-4">Please verify your email address</p>
            <button id="resend-verification-email" className="btn btn-primary" onClick={handleModalClose}>Resend Verification Email</button>
        </div>
    )
}

export default ModalVerifyEmail
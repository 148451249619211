import React, { useEffect, useRef } from 'react'

// import components
import LoadingAnimation from './LoadingAnimation'

// import scss
import '../../assets/scss/components/resume/aiReturns.scss'

const AIReturns = ({ workHistory, activeIndex, loading }) => {
    const returnContainersRef = useRef([]);

   

    useEffect(() => {
        // Ensure that the returnContainersRef is updated when the workHistory changes.
        returnContainersRef.current = returnContainersRef.current.slice(0, workHistory.length);
    }, [workHistory]);

    // useEffect(() => {
        
    //     scrollToActiveItem(activeIndex);
    // }, [activeIndex]);

    useEffect(() => {
         // Scroll to the height of the active item's item-body.
        const scrollToActiveItem = () => {
            // console.log(returnContainersRef.current[activeIndex])
            if (activeIndex >= 0 && returnContainersRef.current[activeIndex]) {
                const itemBody = returnContainersRef.current[activeIndex].querySelector('.item-body');
                if (itemBody) {
                    itemBody.scrollTop = itemBody.scrollHeight;
                }
            }
        };
        // Scroll to the height of the active item's item-body when the component updates.
        const timer = setTimeout(() => {
            scrollToActiveItem();
        }, 50);
        // Clear timeout if the component unmounts
        return () => clearTimeout(timer);
    }, [activeIndex]);

    return (
        workHistory.map((job, jobIndex) => (
            // <div className="work-history-return-cont br-10 mb-2" key={job.id}>
            <div className={`work-history-return-cont br-10 mb-2 ${activeIndex === jobIndex ? 'active' : ''}`} 
            key={job.id} 
            data-index={jobIndex}
            ref={(el) => (returnContainersRef.current[jobIndex] = el)}
            >
                <div className="work-history-item d-flex flex-column">
                    <div className="item-header d-flex justify-content-between p-1 bg-white">
                        <h4 className="item-title sub text-dblue mb-0">{job.title || 'Untitled'}</h4>
                    </div>
                    <div className="item-body flex-column">
                        <div className="item-returns d-flex flex-column">
                            {job.returns.map((ret, returnIndex) => {
                                // Split the 'ret' string into 'items' using ' - '
                                // there is a field for ret.timestamp now

                                // issue with the below code
                                // console.log(ret, returnIndex, ret != null)
                                let items = [];
                                if(ret !== null && (ret.response && ret.response.length > 0 && ret.response !== 'Error generating response')) {
                                    if( ret.response.includes('-') && ret.response.indexOf('-') === 0 ){
                                        items = ret.response.split('- ');
                                        items.shift(); // Remove the first item, which is the empty string before the first ' - '
                                    } else{
                                        items[0] = ret.response;
                                    }
                                } else{
                                    items[0] = ret.response;
                                }

                                return (
                                    <div className="return-item-cont w-100" key={returnIndex}>
                                    <p className="sub sub-sm text-dblue">return</p>
                                    <div className="return-item d-flex justify-content-between align-items-center mb-1 bg-white br-5 p-1">
                                        <ul>
                                            {items.map((item, itemIndex) => (
                                            <li key={itemIndex}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    </div>
                                );
                            })}
                            {loading ? <LoadingAnimation /> : ''}
                        </div>
                    </div>
                    <div className="item-preview">
                        <p className="sub sub-sm text-dblue">Expand for AI Writer replies</p>
                    </div>
                </div>
            </div>
        ))
    )
}

export default AIReturns
import React from 'react'

// import components
import TopBar from '../components/TopBar'
import Header from '../components/account/Header'
import Footer from '../components/Footer'

const RefundPolicy = () => {
    return (
        <>
            <TopBar />
            
            <section className='section-terms-conditions'>
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-md-8 offset-md-2">
                            <Header headerTitle='Refund Policy' />
                                <p>We operate a no questions asked Refund Policy:</p>

                                <p>All purchases are fully refundable within 7-days of purchase if 100% of Resume Re-writes are unused.</p>

                        </div>
                    </div>
                </div>
                
            </section>
            <Footer />
        </>
        
    )
}

export default RefundPolicy
import React from 'react'
import TopBar from '../components/TopBar'
import ResetPasswordForm from '../components/ResetPasswordForm'

const ResetPassword = () => {
    return (
        <div>
            <TopBar page='resetPassword'/>
            <ResetPasswordForm />
        </div>
    )
}

export default ResetPassword
import React, { useState, useEffect} from 'react'
import useAxios from '../utils/useAxios';

// import scss
import '../assets/scss/components/dashProfile.scss';


// import images
import headshotPlaceholder from '../assets/images/headshot-placeholder.svg'

const DashProfile = () => {

    const [firstName, setFirstName] = useState(""); // [{}
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    
    let api = useAxios()

    // Using useEffect to call the function once the component is mounted
    useEffect(() => {
        loadName();
        // if resume length is 0, use dummy data
    }, []);
    

    const loadName = async() => {
        // let token = JSON.parse(localStorage.getItem('authTokens'));
        // console.log(token)
        api
            .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/getUserInfo')
            .then(function(response){
                // console.log(response)
                if(response.status === 200){
                    // set all attributes if not null
                    if (response.data.data != null){
                        if(response.data.data.firstName != null){
                            setFirstName(response.data.data.firstName)
                        }
                        if(response.data.data.lastName != null){
                            setLastName(response.data.data.lastName)
                        }
                        if(response.data.data.email != null){
                            setEmail(response.data.data.email)
                        }
                    }
                }
            
                

                if(response.data != null || !response.data.data.firstName && !response.data.data.lastName && !response.data.data.email){
                    console.log("fetching email!!")
                    fetchEmail()
                }
            })
            .catch(function (error) {
                console.log(error.message);
            });
    }

    const fetchEmail = async() => {
         api
            .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/getRegisterInfo')
            .then(function(response){
                setEmail(response.data.data)
        
            })
    }

    // Determine which value to display based on presence of first name and last name
    const displayName = firstName || lastName ? `${firstName} ${lastName}` : email;
    // const displayName =  `${email}`;


    return (
        <div className="container mt-3 container-dash-profile">
            <div className="row">
                <div className="col col-12 br-10 bg-xlblue p-2">
                    <div className="profile-card d-flex gap-3">
                        <div className="profile-picture">
                            <img className='br-5' src={headshotPlaceholder} alt="headshot" />
                        </div>
                        <div className="profile-meta">
                            <h2>{displayName}</h2>
                            <div className="link-cont">
                                <a href="/account/information" className="inline-link pe-1">Account</a>
                                <a href="/account/settings" className="inline-link pe-1">Settings</a>
                                <a href="/account/billing" className="inline-link pe-1">Billing</a>
                                <a href="/account/subscription" className="inline-link">Subscription</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default DashProfile
import React, { useEffect, useState} from 'react'
import useAxios from '../../utils/useAxios';
import { useNavigate } from 'react-router-dom';

// import scss
import '../../assets/scss/components/account/billing.scss';

// import icons
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';

const Billing = () => {
    ///////////////////////////////////
    // states
    const navigate = useNavigate();
    let api = useAxios()
    const [price, setPrice] = useState();
    const [renewalDate, setRenewalDate] = useState();
    const [billingPeriod, setBillingPeriod] = useState();

    // Using useEffect to call the function once the component is mounted
    useEffect(() => {
        loadBilling();
        // if resume length is 0, use dummy data
    }, []);

    const loadBilling = async() => {
        let token = JSON.parse(localStorage.getItem('authTokens'));
        // console.log(token)
        api
            .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/subscriptionInfo')
            .then(function(response){
                setPrice(response.data.data.price)
                // setRenewalDate(response.data.data.dateCreated)
                setBillingPeriod(response.data.data.billingPeriod)

                const date = new Date(response.data.data.dateCreated)
                const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
                setRenewalDate(formattedDate)
                // console.log(response)
        
            })
            .catch(function (error) {
                console.log(error.message);
                //window.location = '/login'
            });
    }
    
    // async function redirectToStripePortal() {
    //     try {
    //         let token = JSON.parse(localStorage.getItem('authTokens'));
    //         const response = await fetch("/api/billing", {
    //             method: "POST",
    //             headers: {
    //                 'authorization': token,
    //             }
    //         });
    
    //         const data = await response.json();
    //         window.location.href = data.url;  // Redirect the user to the Stripe Customer Portal
    //     } catch (error) {
    //         console.error("Error redirecting to Stripe portal:", error);
    //     }
    // }
 
    //  // Using useEffect to call the function once the component is mounted
    //  useEffect(() => {
    //     redirectToStripePortal();
    //  }, []);
    

    function formatDollars(value) {
        return value / 100; // Convert to dollars
    }

    ///////////////////////////////////
    // handlers
    const handleSubscriptionClick = () => {
        navigate('/account/subscription');
    };



    return (
        <div className="billing-wrap mt-0 mt-sm-3">
            <div className="billing-cont p-3 bg-xlblue br-20">
                <div className="header-cont d-flex mb-2">
                    <h3 className='mb-0 me-2 mb-1 mb-sm-0'>Billing & Payments</h3>
                    <button onClick={handleSubscriptionClick} className="btn-icon btn-inline mb-0"><SellOutlinedIcon fontSize='inherit'/> Edit Subscription</button>
                </div>
                
                <div className="member-info">
                    <p className="sub sub-sm text-dblue">Price:</p>
                    <p>${formatDollars(price)}/{billingPeriod}</p>
                </div>
                <div className="type-cont">
                    <p className="sub sub-sm text-dblue">Renewal Date:</p>
                    <p>{renewalDate}</p>
                </div>

            </div>
        </div>
    )
}

export default Billing
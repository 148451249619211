import React, {useState, useEffect, useMemo} from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

// import components
import InformationForm from './InformationForm';
import Settings from './Settings';
import Billing from './Billing';
import Subscription from './Subscription';

// import scss
import '../../assets/scss/components/account/tabs.scss'

const Tabs = () => {
    ///////////////////////////////////
    // states
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();

    ///////////////////////////////////
    // variables
    const tabTitles = useMemo(
    () => [
          {
            title: 'Account Information',
            slug: '/account/information',
          },
          {
            title: 'Account Settings',
            slug: '/account/settings',
          },
          {
            title: 'Billing',
            slug: '/account/billing',
          },
          {
            title: 'Manage Subscription',
            slug: '/account/subscription',
          },
        ],
        []
    );

    useEffect(() => {
        const currentTab = tabTitles.findIndex((tab) => tab.slug === location.pathname);
        setActiveTab(currentTab !== -1 ? currentTab : 0);
    }, [location.pathname, tabTitles]);

    ///////////////////////////////////
    // handlers
    const handleTabClick = (index, slug) => {
        setActiveTab(index);
        navigate(slug);
    };


    return (
        <div className="container container-tabs">
            <div className="row d-none d-lg-flex">
                <div className="col col-12">
                    <div className="tabs-cont d-flex gap-2">
                        {tabTitles.map((tab, index) => (
                            <button
                                key={index}
                                className={`tab-header-item px-1 ${index === activeTab ? 'active' : ''}`}
                                onClick={() => handleTabClick(index, tab.slug)}
                            >
                                {tab.title}
                            </button>
                        ))}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="tab-content">
                        {activeTab === 0 && <InformationForm />}
                        {activeTab === 1 && <Settings />}
                        {activeTab === 2 && <Billing />}
                        {activeTab === 3 && <Subscription />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tabs
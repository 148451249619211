import React from 'react';
import useAxios from '../utils/useAxios';  // Assuming you have a custom hook for Axios.

// import icons
import AddIcon from '@mui/icons-material/Add';

const DashTemplates = () => {
    const api = useAxios();
    
    const templates = [
        { title: 'Software Engineer', color: '#a8d5e2', id: 'swe'},
        { title: 'Sales Account Manager', color: '#f2d7d5', id: 'sam' },
        { title: 'Supply Chain Analyst', color: '#d1f2eb', id: 'sca' },
        { title: 'Paralegal', color: '#f2d7d5', id: 'paralegal' },
    ];
    
    const handleTemplateClick = async (title, id) => {
        try {
            window.location = `/resume?resumeId=${id}`;
            // const response = await api.post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/createResume', {
            //     name: title
            // });

            // if (response.status === 200) {
            //     // Redirect to a new resume editing page or any other location
            //     window.location = '/resume';  // Change the URL to your desired location.
            // } else {
            //     alert('Failed to create new resume.');
            // }
        } catch (error) {
            alert('An error occurred while creating the resume.');
        }
    };

    return (
        <div className="container container-dash-resumes mt-5">
            <div className="row mb-3">
                <div className="col col-12 d-flex align-items-end">
                    <h2 className="section-title me-2">Templates</h2>
                </div>
            </div>
            
            <div className="row">
                {templates.map((template, index) => (
                    <div 
                        className="col col-12 col-md-6 col-lg-4 mb-3"
                        key={index}
                        onClick={() => handleTemplateClick(template.title, template.id)}
                        style={{ cursor: 'pointer' }}
                    >
                        <div 
                            className="resume-card br-10 h-100 d-flex flex-column align-items-center justify-content-center"
                            style={{ backgroundColor: template.color, minHeight: '200px' }}
                        >
                            <AddIcon />
                            <h5 className="title mt-2">{template.title}</h5>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DashTemplates;

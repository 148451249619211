import React from 'react'

const Home = () => {
    return (
        <div>
        <title>Boosted Resume</title>
        <h1>Home</h1>
        </div>
    )
}

export default Home

import React, {useState, useEffect} from 'react'
import useAxios from '../utils/useAxios';

// import icons
import CheckIcon from '@mui/icons-material/Check';

// import scss
import '../assets/scss/components/pricing.scss'

const Pricing = () => {

    const [activeTab, setActiveTab] = useState('annual'); // 'monthly' or 'annual'
    const [currentSub, setCurrentSub] = useState('free'); // 'free', 'basic', or 'enterprise'
    const [currentBillingPeriod, setCurrentBillingPeriod] = useState('monthly'); // 'monthly' or 'annual'
    const [accountType, setAccountType] = useState('Free'); // 'Free', 'Basic', or 'Enterprise'
    let api = useAxios()

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleOrder = (activeTab, product_id) => {

        if (activeTab === 'annual') {
            product_id = product_id + '_annual'
        }
        let token = JSON.parse(localStorage.getItem('authTokens'));
        console.log(token)
        api
          .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/order/' + product_id)
          .then(function(response){
            console.log(response.data);
            let url = response.data.url;
            window.location = url
          })
          .catch(function (error) {
            console.log(error.message);
            //window.location = '/login'
          });
        
    };

    const loadInfo = async() => {
        let token = JSON.parse(localStorage.getItem('authTokens'));
        console.log(token)
        api
          .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/getSettingsInfo')
          .then(function(response){
            let productId = response.data.data.product
            setAccountType(productId)
            console.log(response)
          })
          .catch(function (error) {
            console.log(error.message);
          });
    }

    // Using useEffect to call the function once the component is mounted
    useEffect(() => {
        loadInfo();
    }, []);


    return (
        <div className="row row-pricing py-5">

            <div className="pricing-nav mt-2 br-10 d-inline-flex mb-4 justify-content-center">
                <div className="tab-cont d-flex bg-xlblue br-10">
                    <div className="tab-item tab-work-history px-2 py-1 active">
                        <button 
                            className={`btn btn-icon btn-tab ${activeTab === 'monthly' ? 'active' : ''}`}
                            onClick={() => handleTabChange('monthly')}
                        >
                        Monthly Billing</button>
                    </div>
                    <div className="tab-item tab-job-description px-2 py-1">
                        <button 
                            className={`btn btn-icon btn-tab ${activeTab === 'annual' ? 'active' : ''}`}
                            onClick={() => handleTabChange('annual')}
                        >
                        Annual Billing</button>
                    </div>
                </div>
            </div>


            <div className="col col-12 col-lg-4 col-pricing">
                <div className="pricing-card mb-2 p-2 bg-xlblue br-10">
                    <h4 className="plan-title mb-2">Free</h4>
                    <p className="plan-price-sub sub sub-sm text-dblue mb-0">Price</p>
                    <p className="plan-price mb-2"><span className='h4'>$0</span>/month</p>
                    <p className="plan-price-sub sub sub-sm text-dblue mb-0">Billing Period</p>
                    <p className="plan-price mb-1 mb-3">{activeTab === 'monthly' ? 'Monthly' : 'Annual'}</p>
                    <button className={accountType === "free" ? "btn btn-xlgreen w-100 mb-1" : "btn btn-primary w-100 mb-1"}>{accountType === "free" ? "Active" : "Upgrade"}</button>
                    <div className="plan-features mt-2 p-2 bg-white br-5">
                        <div className="feature-item d-flex">
                            <CheckIcon className="me-1" fontSize='inherit'/>
                            <p className="usage-item text-dblue mb-0 text-black">5 Job History Re-writes</p>
                        </div>
                        <div className="feature-item d-flex">
                            <CheckIcon className="me-1" fontSize='inherit'/>
                            <p className="usage-item text-dblue mb-0 text-black">1 Resume</p>
                        </div>
                        <div className="feature-item d-flex">
                            <CheckIcon className="me-1" fontSize='inherit'/>
                            <p className="usage-item text-dblue mb-0 text-black">1 Job Description</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col col-12 col-lg-4 col-pricing">
                <div className="pricing-card mb-2 p-2 bg-xlblue br-10">
                    <h4 className="plan-title mb-2">Basic</h4>
                    <p className="plan-price-sub sub sub-sm text-dblue mb-0">Price</p>
                    <p className="plan-price mb-2"><span className='h4'>{activeTab === 'monthly' ? '$24' : '$19'}</span>/month</p>
                    <p className="plan-price-sub sub sub-sm text-dblue mb-0">Billing Period</p>
                    <p className="plan-price mb-3">{activeTab === 'monthly' ? 'Monthly' : 'Annual'}</p>
                    <button className={accountType === "basic" || accountType === "basic_annual"  ? "btn btn-xlgreen w-100 mb-1" : "btn btn-primary w-100 mb-1"}  onClick={() => handleOrder(activeTab, 'basic')}>{accountType === "free" ? "Active" : "Upgrade"}</button>
                    <div className="plan-features mt-2 p-2 bg-white br-5">
                        <div className="feature-item d-flex">
                            <CheckIcon className="me-1" fontSize='inherit'/>
                            <p className="usage-item text-dblue mb-0 text-black">200 Job History Re-writes</p>
                        </div>
                        <div className="feature-item d-flex">
                            <CheckIcon className="me-1" fontSize='inherit'/>
                            <p className="usage-item text-dblue mb-0 text-black">Unlimited Resumes</p>
                        </div>
                        <div className="feature-item d-flex">
                            <CheckIcon className="me-1" fontSize='inherit'/>
                            <p className="usage-item text-dblue mb-0 text-black">Unlimited Job Descriptions</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col col-12 col-lg-4 col-pricing">
                <div className="pricing-card mb-2 p-2 bg-xlblue br-10">
                    <h4 className="plan-title mb-2">Enterprise</h4>
                    <p className="plan-price-sub sub sub-sm text-dblue mb-0">Price</p>
                    <p className="plan-price mb-2"><span className='h4'>{activeTab === 'monthly' ? '$119' : '$99'}</span>/month</p>
                    <p className="plan-price-sub sub sub-sm text-dblue mb-0">Billing Period</p>
                    <p className="plan-price mb-3">{activeTab === 'monthly' ? 'Monthly' : 'Annual'}</p>
                    <button className={accountType === "pro" || accountType === "pro_annual"  ? "btn btn-xlgreen w-100 mb-1" : "btn btn-primary w-100 mb-1"}  onClick={() => handleOrder(activeTab, 'pro')}>{accountType === "free" ? "Active" : "Upgrade"}</button>
                    <div className="plan-features mt-2 p-2 bg-white br-5">
                        <div className="feature-item d-flex">
                            <CheckIcon className="me-1" fontSize='inherit'/>
                            <p className="usage-item text-dblue mb-0 text-black">Unlimited Job History Re-writes</p>
                        </div>
                        <div className="feature-item d-flex">
                            <CheckIcon className="me-1" fontSize='inherit'/>
                            <p className="usage-item text-dblue mb-0 text-black">Unlimited Resumes</p>
                        </div>
                        <div className="feature-item d-flex">
                            <CheckIcon className="me-1" fontSize='inherit'/>
                            <p className="usage-item text-dblue mb-0 text-black">Unlimited Job Descriptions</p>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        
    )


}

export default Pricing
import React from 'react'

// import components
import brLogoBw from '../assets/images/br-logo-bw.svg';
import brLogoColor from '../assets/images/br-logo-color.svg';

// import scss
import '../assets/scss/components/footer.scss'

const Footer = () => {
    let logo = brLogoColor

    return (
        <footer className="footer bg-noise py-5 mt-5">
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-md-6 ps-4 ps-md-0">
                        <img className="footer-logo" src={logo} alt="boosted resume logo" />
                    </div>
                    <div className="col col-12 col-md-2 my-4 my-md-0 ps-4 ps-md-0">
                        <p className='sub text-black mb-2 footer-nav-header'><span>Navigation</span></p>
                        <ul className="footer-links footer-page-nav ps-0 mb-0">
                            <li><a className='footer-link' href="/account/settings">Account</a></li>
                            <li><a className='footer-link' href="/account/billing">Billing</a></li>
                            <li><a className='footer-link' href="/dashboard">Dashboard</a></li>
                        </ul>
                    </div>
                    <div className="col col-12 col-md-2 mb-4 mb-mb-0 ps-4 ps-md-0">
                        <p className='sub text-black mb-2 footer-nav-header'><span>Resources</span></p>
                        <ul className="footer-links footer-page-nav ps-0 mb-0">
                            <li><a className='footer-link' href="https://boostedresume.com/articles">Blog</a></li>
                            <li><a className='footer-link' href="https://boostedresume.com/faq">FAQ</a></li>
                        </ul>
                    </div>
                    <div className="col col-12 col-md-2 ps-4 ps-md-0">
                        <p className='sub text-black mb-2 footer-nav-header'><span>More</span></p>
                        <ul className="footer-links footer-page-nav ps-0 mb-0">
                            <li><a className='footer-link' href="/terms-and-conditions">Terms & Conditions</a></li>
                            <li><a className='footer-link' href="/privacy-policy">Privacy Policy</a></li>
                            <li><a className='footer-link' href="/refund-policy">Refund Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
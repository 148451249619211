import React, { useState, useEffect} from 'react'
import useAxios from '../../utils/useAxios';
// import components
import ResetPasswordForm from './ResetPasswordForm';
import ChangeEmailForm from './ChangeEmailForm';

// import icons
import CloseIcon from '@mui/icons-material/Clear';

const Settings = () => {

    // create a variable called date created
    const [dateCreated, setSignupDate] = useState('July 7, 2023');
    // create a variable called account type
    const [accountType, setSubPlan] = useState('Free Plan');

    let api = useAxios()

     const loadInfo = () => {
        // let token = JSON.parse(localStorage.getItem('authTokens'));
        // console.log(token)
        api
          .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/subscriptionInfo')
          .then(function(response){
            setSignupDate(response.data.data.dateCreated)
            setSubPlan(response.data.data.name)

            console.log(response)
          })
          .catch(function (error) {
            console.log(error.message);
          });

    }

    // Using useEffect to call the function once the component is mounted
    useEffect(() => {
        console.log("Am I printing anything?")
        loadInfo();
        // if resume length is 0, use dummy data
    }, []);

    function formatDate(timestamp) {
        const date = new Date(timestamp); // Convert seconds to milliseconds
        const options = { year: 'numeric', month: 'long', day: 'numeric' }; 
        return date.toLocaleDateString('en-US', options);
    }
    

    return (
        <div className="settings-wrap mt-0 mt-lg-3">
            <div className="setting-cont p-3 bg-xlblue br-20">
                <h3 className='mb-2'>Account Settings</h3>
                <div className="member-info">
                    <p className="sub sub-sm text-dblue">Member Since:</p>
                    <p>{formatDate(dateCreated)}</p>
                </div>
                <div className="type-cont">
                    <p className="sub sub-sm text-dblue">Account Type:</p>
                    <p>{accountType}</p>
                </div>

            </div>

            <div className="login-cont p-3 bg-xlblue br-20 mt-3">
                <h3 className='mb-2'>Login Settings</h3>
                <ChangeEmailForm />
                <ResetPasswordForm />
            </div>
        </div>
        
        
    )
}

export default Settings
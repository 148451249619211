import React from 'react'
import TopBar from '../components/TopBar'
import DashProfile from '../components/DashProfile'
import DashResumes from '../components/DashResumes'
import DashTemplates from '../components/DashTemplates'
import Footer from '../components/Footer'

const Dashboard = () => {
    return (
        <section className="section-dashboard">
            <TopBar />
            <DashProfile />
            <DashResumes />
            <DashTemplates />
            <Footer />
        </section>  
    )
}

export default Dashboard
import React from 'react'
import { useState } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import dayjs from 'dayjs'
import jwt_decode from "jwt-decode";

const PrivateRoutes = () => {
    const authToken = useState(()=> localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)

    if(authToken === null || !authToken[0]){
        return <Navigate to="/login"/>
    }

    const user = jwt_decode(authToken[0])
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if(!isExpired){
        return(
            <Outlet/>
            // authToken ? <Outlet/> : <Navigate to="/login"/>
        )
    } else {
        return <Navigate to="/login"/>
    }

    
}

export default PrivateRoutes
import React, {useState, useEffect} from 'react'
import Pricing from '../../components/Pricing'

import useAxios from '../../utils/useAxios';

// import icons
import CheckIcon from '@mui/icons-material/Check';

const Subscription = () => {

    const [subPlan, setSubPlan] = useState('Free Plan');
    // create variables for job history re-writes, resumes created and number of job descriptions.
    const [jobHistory, setJobHistory] = useState(0);
    const [resumes, setResumes] = useState(0);
    const [jobDescriptions, setJobDescriptions] = useState(0);
    // create variables for plan specific info that depends on account type
    const [planPrice, setPlanPrice] = useState(0);
    const [billingPeriod, setBillingPeriod] = useState('Monthly');
    const [signupDate, setSignupDate] = useState('July 7, 2023');
    // create variables for plan features
    const [jobHistoryRewritesTotal, setJobHistoryRewritesTotal] = useState(0);
    const [resumesCreatedTotal, setResumesCreatedTotal] = useState(0);
    const [jobDescriptionsTotal, setJobDescriptionsTotal] = useState(0);

    let api = useAxios()

    const handleCancel = () => {
        console.log('cancel plan')
        let token = JSON.parse(localStorage.getItem('authTokens'));
        console.log(token)
        api
          .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/cancel')
          .then(function(response){
            console.log(response)
          })
          .catch(function (error) {
            console.log(error.message);
          });

    }

    const loadInfo = () => {

        let token = JSON.parse(localStorage.getItem('authTokens'));
        console.log(token)
        api
          .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/subscriptionInfo')
          .then(function(response){
            setBillingPeriod(response.data.data.billingPeriod)
            setJobDescriptions(response.data.data.used_descriptions)
            setJobDescriptionsTotal(response.data.data.total_descriptions)
            setJobHistory(response.data.data.used_writes)
            setJobHistoryRewritesTotal(response.data.data.total_writes)
            setPlanPrice(response.data.data.price)
            setResumes(response.data.data.used_resumes)
            setResumesCreatedTotal(response.data.data.total_resumes)
            setSignupDate(response.data.data.dateCreated)
            setSubPlan(response.data.data.name)

            console.log(response)
          })
          .catch(function (error) {
            console.log(error.message);
          });


    }

    // Using useEffect to call the function once the component is mounted
    useEffect(() => {
        loadInfo();
    }, []);

    function formatDate(timestamp) {
        const date = new Date(timestamp); // Convert seconds to milliseconds
        const options = { year: 'numeric', month: 'long', day: 'numeric' }; 
        return date.toLocaleDateString('en-US', options);
    }

    function formatDollars(value) {
        return value / 100; // Convert to dollars
    }



    return (
        
        <div className="manage-subscription">
            <div className="plan-info-cont">
                <h2 className='mt-2'>Your {subPlan} Plan</h2>

                <div className="row bg-xlblue px-2 py-5 br-10">
                    <div className="col col-12 col-lg-4 mb-2 mb-lg-0">
                        <h4 className="mb-2">Usage</h4>
                        <div className="card card-usage bg-white p-2 br-10 mb-1">
                            <div className="card-item d-flex justify-content-between">
                                <p className="feature-item">Job History Re-writes</p>
                                <p className="usage-item text-dblue">{jobHistory}<span className="text-xldblue">/{jobHistoryRewritesTotal}</span></p>
                            </div>
                            <div className="card-item d-flex justify-content-between">
                                <p className="feature-item">Resumes Created</p>
                                <p className="usage-item text-dblue">{resumes}<span className="text-xldblue">/{resumesCreatedTotal}</span></p>
                            </div>
                            <div className="card-item d-flex justify-content-between">
                                <p className="feature-item mb-0">Job Descriptions Used</p>
                                <p className="usage-item text-dblue mb-0">{jobDescriptions}<span className="text-xldblue">/{jobDescriptionsTotal}</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="col col-12 col-lg-4">
                        <h4 className="mb-2">Features</h4>
                        <div className="card card-usage bg-white p-2 br-10 mb-2">
                            <div className="card-item d-flex justify-content-between">
                                <CheckIcon fontSize='inherit'/>
                                <p className="usage-item text-dblue"> {jobHistoryRewritesTotal} Job History Re-writes</p>
                            </div>
                            <div className="card-item d-flex justify-content-between">
                                <CheckIcon fontSize='inherit'/>
                                <p className="usage-item text-dblue">{resumesCreatedTotal} Resume</p>
                            </div>
                            <div className="card-item d-flex justify-content-between">
                                <CheckIcon fontSize='inherit'/>
                                <p className="usage-item text-dblue mb-0"> {jobDescriptionsTotal} Job Description</p>
                            </div>
                        </div>
                    </div>

                    <div className="col col-12 col-lg-4">
                        <h4 className="mb-2">Billing</h4>
                        <div className="card card-usage bg-white p-2 br-10 mb-1">
                            <div className="card-item d-flex justify-content-between">
                                <p className="feature-item">Price</p>
                                <p className="usage-item text-dblue">${formatDollars(planPrice)}/{billingPeriod === 'month' ? "month" : "year"}</p>
                            </div>
                            <div className="card-item d-flex justify-content-between">
                                <p className="feature-item">Billing Period</p>
                                <p className="usage-item text-dblue">{billingPeriod === 'month' ? "Monthly" : "Yearly"}</p>
                            </div>
                            <div className="card-item d-flex justify-content-between">
                                <p className="feature-item mb-0">Signup Date</p>
                                <p className="usage-item text-dblue mb-0">{formatDate(signupDate)}</p>
                            </div>
                        </div>
                        <button className="btn btn-primary btn-xlred w-100" onClick={() => handleCancel()}>Cancel Plan</button>
                    </div>
                </div>
            </div>
            <Pricing />
        </div>
    )
}

export default Subscription
import React from 'react';
import '../../assets/scss/components/resume/jobDescription.scss';
import useAxios from '../../utils/useAxios';

const JobDescription = ({ resumeId, jobDescription, setJobDescription }) => {
    let api = useAxios();

    // Logging the prop value for debugging
    console.log('JobDescription prop jobDescription:', jobDescription);

    const handleTextareaChange = (event) => {
        setJobDescription(event.target.value);  
    };

    const handleSubmit = (e) => {
        if (e.type === 'keypress' && e.key !== 'Enter') {
            return;
        }

        if (e.type === 'keypress') {
            e.preventDefault();  // Prevent default Enter behavior
        }

        const currentText = e.target.value;

        const formData = new FormData();
        formData.append('description', currentText);

        api.post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/editJob/' + resumeId, formData)
            .then(response => {
                if (response.status === 200) {
                    // Logging the entire response for debugging
                    console.log('API response:', response.data);

                    const updatedDescription = response.data.description;
                    setJobDescription(updatedDescription);
                }
            })
            .catch(error => {
                console.error('Error editing resume:', error);
            });
    }

    return (
        <div className="job-description-cont bg-xlblue p-2 br-20">
            <label htmlFor="job-description" className="form-label sub sub-sm text-dblue">Job Description</label>
            <textarea
                className="job-description-input w-100 br-10 p-1"
                label="job-description"
                rows="20"
                value={jobDescription}
                // onKeyPress={handleSubmit}
                onBlur={handleSubmit}
                onChange={handleTextareaChange}
                placeholder="Enter the job description here..."
            />
        </div>
    );
};

export default JobDescription;

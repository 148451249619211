import React, { useState, useRef, useEffect } from 'react'
import useAxios from '../../utils/useAxios';


//import scss
import '../../assets/scss/components/account/informationForm.scss'

// import images
import headshot from '../../assets/images/headshot-default.png'

// import icons
import AddIcon from '@mui/icons-material/Add';

const InformationForm = () => {

    // set variables
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [nationality, setNationality] = useState('');
    const [shortBio, setShortBio] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');
    const [headshotImage, setHeadshotImage] = useState(headshot);
    const fileInputRef = useRef(null);

    let api = useAxios()

    const getInformation = async() => {
        api
            .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/getUserInfo')
            .then(function(response){
                // console.log(response)
                if(response.status === 200){
                    // set all attributes if not null
                    if(response.data.data.firstName != null){
                        setFirstName(response.data.data.firstName)
                    }
                    if(response.data.data.lastName != null){
                        setLastName(response.data.data.lastName)
                    }
                    if(response.data.data.email != null){
                        setEmail(response.data.data.email)
                    }
                    if(response.data.data.phoneNumber != null){
                        setPhoneNumber(response.data.data.phoneNumber)
                    }
                    if(response.data.data.dateOfBirth != null){
                        setDateOfBirth(response.data.data.dateOfBirth)
                    }
                    if(response.data.data.nationality != null){
                        setNationality(response.data.data.nationality)
                    }
                    if(response.data.data.shortBio != null){
                        setShortBio(response.data.data.shortBio)
                    }
                    if(response.data.data.street != null){
                        setStreet(response.data.data.street)
                    }
                    if(response.data.data.city != null){
                        setCity(response.data.data.city)
                    }
                    if(response.data.data.zip != null){
                        setZip(response.data.data.zip)
                    }
                    if(response.data.data.country != null){
                        setCountry(response.data.data.country)
                    }
                    if(response.data.data.headshotImage != null){
                        setHeadshotImage(response.data.data.headshotImage)
                    }
                }else{
                    // console.log(response.status)
                    alert('Something went wrong!')
                    window.location = '/login'
                }
            })
            .catch(function (error) {
                console.log(error.message);
                //window.location = '/login'
            });
    }


    // handlers
    const handleSubmit = (e) => {
        e.preventDefault();
        let token = JSON.parse(localStorage.getItem('authTokens'));
        console.log(token)
        // TODO: potential issue
        const formData = new FormData();
        
        formData.append('first', firstName);
        formData.append('last', lastName);
        formData.append('email', email);
        formData.append('number', phoneNumber);
        formData.append('dateOfBirth', dateOfBirth);
        formData.append('nationality', nationality);
        formData.append('shortBio', shortBio);
        formData.append('street', street);
        formData.append('city', city);
        formData.append('zipcode', zip);
        formData.append('country', country);
        formData.append('photo', headshotImage);

        api.post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/userinfo', formData)
            .then(function(response) {
                console.log(response);
                if (response.status === 200) {
                    console.log(response.status);
                } else {
                    console.log(response.status);
                    alert('Something went wrong!');
                    window.location = '/login';
                }
            })
            .catch(function(error) {
                console.log(error.message);
                window.location = '/login';
            });
    
        // Perform form submission logic here
        // You can access the form values using the state variables
    };

    useEffect(() => {
        getInformation();
        // if resume length is 0, use dummy data
    }, []);

    const handleHeadshotClick = () => {
        fileInputRef.current.click();
    };
    
    const handleImageUpload = (e) => {
        console.log(e.target.files)
        const file = e.target.files[0];
        setHeadshotImage(file);
    };

    return (
        <div className="information-cont mt-0 mt-lg-3 p-2 bg-xlblue br-20">
            <form className='account-info-form d-flex flex-wrap' onSubmit={handleSubmit}>
                {/* <div className='headshot-cont d-flex flex-column'>
                    <label className="sub sub-sm text-dblue" htmlFor="headshotImage">Headshot:</label>
                    <div className="img-cont d-flex align-items-center justify-content-center br-10" onClick={handleHeadshotClick}>
                        {headshotImage && <img className='headshot-img' src={headshotImage} alt="Uploaded" onClick={handleHeadshotClick} />}
                        <AddIcon className='add-icon' />
                    </div>
                    

                    <input
                        ref={fileInputRef}
                        type="file"
                        className='d-none'
                        onChange={handleImageUpload}
                        accept="image/*"
                        id="headshotImage"
                    />
                    
                </div> */}

                <div className="info-cont d-flex flex-row flex-wrap">
                    <div className='d-flex flex-column info-item-cont first-name-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="firstName">First Name:</label>
                        <input type="text" id="firstName" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                    <div className='d-flex flex-column info-item-cont last-name-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="lastName">Last Name:</label>
                        <input type="text" id="lastName" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <div className='d-flex flex-column info-item-cont email-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="email">Email:</label>
                        <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='d-flex flex-column info-item-cont phone-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="phoneNumber">Phone Number:</label>
                        <input type="tel" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>
                    <div className='d-flex flex-column info-item-cont dob-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="dateOfBirth">Date of Birth:</label>
                        <input type="date" id="dateOfBirth" value={dateOfBirth} onChange={(e) => setDateOfBirth(e.target.value)} />
                    </div>
                    <div className='d-flex flex-column info-item-cont nationality-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="nationality">Nationality:</label>
                        <input type="text" id="nationality" value={nationality} onChange={(e) => setNationality(e.target.value)} />
                    </div>
                    <div className='d-flex flex-column info-item-cont bio-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="shortBio">Short Bio:</label>
                        <textarea id="shortBio" value={shortBio} onChange={(e) => setShortBio(e.target.value)} />
                    </div>
                </div>
                

                <div className="address-cont d-flex flex-row flex-wrap">
                    <p className="sub sub-sm divider-bottom-lblue text-dblue w-100">Address</p>

                    <div className='d-flex flex-column info-item-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="street">Street:</label>
                        <input type="text" id="street" value={street} onChange={(e) => setStreet(e.target.value)} />
                    </div>
                    <div className='d-flex flex-column info-item-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="city">City:</label>
                        <input type="text" id="city" value={city} onChange={(e) => setCity(e.target.value)} />
                    </div>
                    <div className='d-flex flex-column info-item-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="zip">ZIP Code:</label>
                        <input type="text" id="zip" value={zip} onChange={(e) => setZip(e.target.value)} />
                    </div>
                    <div className='d-flex flex-column info-item-cont'>
                        <label className="sub sub-sm text-dblue" htmlFor="country">Country:</label>
                        <input type="text" id="country" value={country} onChange={(e) => setCountry(e.target.value)} />
                    </div>
                </div>
                
                <button className="btn btn-primary" type="submit">Submit</button>
            </form>
        </div>
    )
}

export default InformationForm
import React from 'react'

// import scss
import '../../assets/scss/components/resume/loadingAnimation.scss'

const LoadingAnimation = () => {
    return (
        <div className="loading-container w-100 text-center">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    )
}

export default LoadingAnimation
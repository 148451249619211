import React, { useState, useEffect } from 'react';
// import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';
import useAxios from '../utils/useAxios';

// import scss
import '../assets/scss/pages/resume.scss';

// import components
import TopBar from '../components/TopBar';
import ResumeNav from '../components/resume/ResumeNav';
import EditNav from '../components/resume/EditNav';
import PreviewNav from '../components/resume/PreviewNav';
import WorkHistory from '../components/resume/WorkHistory';
import JobDescription from '../components/resume/JobDescription';
import AIReturns from '../components/resume/AIReturns';

const Resume = () => {

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    // Example: to get the value of a query parameter named 'resumeId'
    const _resumeId = query.get('resumeId');
    // let api = useAxios();
    // const [api, setApi] = useState(useAxios());
    const api = useAxios();

    const [activeIndex, setActiveIndex] = useState(0);

    const [editType, setEditType] = useState('workHistory')
    const [workHistory, setWorkHistory] = useState([]);
    const [resumeID, setResumeID] = useState(_resumeId);
    const [resumeName, setResumeName] = useState('New Resume');
    const [jobDescription, setJobDescription] = useState('Just pulled this from the database!');
    const [loading, setLoading] = useState(false);

    
    useEffect(() => {
        const loadResumes = async() => {
            // let token = JSON.parse(localStorage.getItem('authTokens'));
    
            api
                .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/getContent/' + resumeID)
                .then(function(response) {
                    if (response.status === 201) {
                        setResumeID(response.data.data.id);
                        setWorkHistory([])                       
                        //setResumes([]);
                    } else if (response.status === 200) {
                        setResumeID(response.data.id);
                        setWorkHistory(response.data.data);
                        setResumeName(response.data.name);
                        setJobDescription(response.data.description)
                    } else {
                        alert('Something went wrong!');
                        window.location = '/login';
                    }
                })
                .catch(function(error) {
                    window.location = '/login';
                });
        };

        loadResumes();
    }, [resumeID, resumeName]);

    const handleLoading = (childState) => {
        setLoading(childState);
    }

    const handleChildState = (childState) => {
        setEditType(childState);
    };

    const handleChildWorkHistory = (childState) => {
        setWorkHistory(childState);
        console.log(childState);
        // setActiveIndex(childState);
    };

    const handleActiveIndex = (childState) => {
        setActiveIndex(childState);
        
    };

    return (
        <section className="section-resume">
            <TopBar />
            <ResumeNav resumeId={resumeID} resumeName={resumeName}/>

            <div className="container-fluid">
                <div className="row">
                    <div className="col col-12 col-lg-6">
                        <EditNav editType={editType} onChildStateChange={handleChildState}/>
                        {editType === 'workHistory' ? 
                            <WorkHistory 
                            key={resumeID}
                            workHistory={workHistory} 
                            onChildStateChange={handleChildWorkHistory} 
                            activeIndex={activeIndex} 
                            handleActiveIndex={handleActiveIndex} 
                            resumeId={resumeID}
                            handleLoading={handleLoading}
                            loading={loading}
                            /> 
                        : 
                            <JobDescription 
                            resumeId={resumeID} 
                            jobDescription={jobDescription} 
                            setJobDescription={setJobDescription}/>
                        }
                    </div>
                    <div className="col col-6 bg-noise d-none d-lg-block">
                        <PreviewNav/>
                        <AIReturns workHistory={workHistory} activeIndex={activeIndex} handleActiveIndex={handleActiveIndex} loading={loading}/>
                    </div>
                </div>
            </div>
            

        </section>
    )
}

export default Resume
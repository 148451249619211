import React, { useState, useEffect } from 'react'

// import icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useAxios from '../../utils/useAxios';

// import scss
import '../../assets/scss/components/resume/resumeNav.scss';

// import icons
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import AddIcon from '@mui/icons-material/Add';

const ResumeNav = ({resumeId, resumeName}) => {
    let api = useAxios()

    const [resumes, setResumes] = useState([])
    const [resumeTitle, setResumeTitle] = useState('');  // initialize to empty string or default value
    const [resumeID, setResumeID] = useState('');       // initialize to empty string or default value
    const [activeResumes, setActiveResumes] = useState([]);


    const handleNavClick = (target) => {
        if(target === 'dashboard'){
            window.location = '/dashboard'
        }

    };

    const handleAddResume = (e) => {
        window.location = '/resume?resumeId=new';
        console.log("handleAddClick triggered", e);
    }

    const handleEditResumetitle = () => {
        document.querySelector('.tab-resume p').style.display = 'none';
        document.querySelector('.tab-resume form').style.display = 'block';
        document.querySelector('.tab-resume input').focus();
    }

    const handleSubmit = (e) => {
        const formData = new FormData();
        
        formData.append('name', resumeTitle);

        api.post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/editResumeName/' + resumeID , formData)
        .then(response => {
            if (response.status === 200) {
                //alert('Resume name edited successfully.');
            }
        })
        .catch(error => {
            //alert('Error editing resume name.');
        });
        // /api/editResumeName/<resumeID>
        e.preventDefault();
        document.querySelector('.tab-resume p').style.display = 'block';
        document.querySelector('.tab-resume form').style.display = 'none';
    }
    
    useEffect(() => {
        setResumeTitle(resumeName);
        setResumeID(resumeId);
    }, [resumeName, resumeId]);

    return (
        <div className="resume-nav w-100 px-1 px-lg-3">
            <div className="tab-cont d-flex">
                <div className="tab-item tab-dashboard">
                    <button className="btn-inline btn-icon text-white" onClick={() => handleNavClick('dashboard')}><ArrowBackIosNewIcon fontSize='inherit'/><span className='d-none d-md-inline'>Dashboard</span></button>
                </div>

                {activeResumes.map((resumeData, index) => (
                    <div key={index} className="tab-item tab-resume ps-2 pe-2 py-1 d-flex align-items-center justify-content-center active">
                        <p className="mb-0 pe-2 resume-title" onClick={handleEditResumetitle}>
                        {resumeData.resumeTitle}
                        </p>
                        <CreateOutlinedIcon className="edit-icon" fontSize="inherit text-xldblue" onClick={handleEditResumetitle} />
                    </div>
                ))}
                
                <div className="tab-item tab-resume ps-2 pe-2 py-1 d-flex align-items-center justify-content-center active">
                    <form 
                    onSubmit={handleSubmit}
                    onBlur={handleSubmit}
                    id='edit-resume'
                    className='hide'>
                        <input type="text" 
                        className="pe-2" 
                        placeholder="New Resume"
                        value={resumeTitle}
                        onChange={(e) => setResumeTitle(e.target.value)}
                        />
                    </form>
                    <p className="mb-0 pe-2 resume-title"
                    onClick={handleEditResumetitle}>{resumeTitle}</p>
                    <CreateOutlinedIcon 
                    className='edit-icon' 
                    fontSize='inherit text-xldblue' 
                    onClick={handleEditResumetitle}
                    />
                </div>
                <div className="tab-item tab-add">
                    <button 
                    className="text-white bg-dblue h-100 d-flex align-items-center"
                    onClick={handleAddResume}
                    >
                        <AddIcon fontSize='medium'/>
                    </button>
                </div>
            </div>
        </div>
    )
}


export default ResumeNav
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

// import icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const Header = ({headerTitle}) => {
    ///////////////////////////////////
    // states
    const navigate = useNavigate();
    const [title, setTitle] = useState(headerTitle || 'Account')


    ///////////////////////////////////
    // handlers
    const handleBackClick = () => {
        navigate('/dashboard');
    };

    return (
        <div className="container container-header py-4">
            <div className="row">
                <div className="col col-12 d-flex">
                    <h2 className="section-title me-2">{title}</h2>
                    <button className="btn-icon btn-inline" onClick={handleBackClick}><ArrowBackIosNewIcon fontSize='inherit'/>Back To Dashboard</button>
                </div>
            </div>
        </div>
    )
}

export default Header
import React, { useState, useEffect } from 'react';
import useAxios from '../utils/useAxios';

// import icons
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// import scss
import '../assets/scss/components/dashResumes.scss';

const DashResumes = () => {
    const [confirmDeleteIndex, setConfirmDeleteIndex] = useState(-1);
    const [resumes, setResumes] = useState([]);
    let api = useAxios();

    const loadResumes = async() => {
        let token = JSON.parse(localStorage.getItem('authTokens'));
        api
            .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/getResumes')
            .then(function(response) {
                console.log("happening here!!")
                console.log(response.data.data)
                if (response.data.data == null || response.data.data.length == 0) {
                    setResumes([]
                )                       
                    //setResumes([]);
                } else if (response.status === 200) {
                    console.log("This far??>")
                    console.log(response.data.data)
                    setResumes(response.data.data);
                } else {
                    alert('Something went wrong!');
                    window.location = '/login';
                }
            })
            .catch(function(error) {
                window.location = '/login';
            });
    };

    useEffect(() => {
        loadResumes();
    }, []);

    const defaultResumeTitle = 'Untitled Resume';

    const handleConfirmDelete = (e) => {
        const resumeIndexToDelete = resumes.findIndex(resume => resume.id === confirmDeleteIndex);

        if (resumeIndexToDelete === -1) {
            console.error("Couldn't find the resume to delete with the given ID:", confirmDeleteIndex);
            return;
        }

        const resumeToDelete = resumes[resumeIndexToDelete];
        console.log("Trying to delete a resume:", resumeToDelete);
            
        // Here you can add an API call to your backend to delete the resume from the database
        // if your backend supports it.
        // Example:
        api.post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/deleteResume/' + confirmDeleteIndex)
        .then(response => {
          if(response.status === 200) {
            //alert('Resume deleted successfully.');
          }
        })
        .catch(error => {
          alert('Error deleting resume.');
        });

        const updatedResumes = [...resumes];
        updatedResumes.splice(resumeIndexToDelete, 1);
        setResumes(updatedResumes);
        console.log(resumes); // Note: This will still print the old resumes list since state updates are asynchronous
        setConfirmDeleteIndex(-1);
        e.stopPropagation();
    };

    const handleCancelDelete = (e) => {
        setConfirmDeleteIndex(-1);
        e.stopPropagation();
    };

    const handleAddClick = (e) => {
        window.location = '/resume?resumeId=new';
        console.log("handleAddClick triggered", e);
    };

    const handleResumeClick = (e, resumeId, action) => {
        if (action === 'delete') {
            setConfirmDeleteIndex(resumeId);
            e.stopPropagation();
        } else if (action === 'edit') {
            window.location = `/resume?resumeId=${resumeId}`;
        }
    };

    function formatDate(timestamp) {
        const date = new Date(timestamp); // Convert seconds to milliseconds
        const options = { year: 'numeric', month: 'long', day: 'numeric' }; 
        return date.toLocaleDateString('en-US', options);
    }

    return (
        <div className="container container-dash-resumes mt-3 mt-md-5">
            <div className="row mb-3 row-header">
                <div className="col col-12 d-flex align-items-end">
                    <h2 className="section-title me-2">Resumes</h2>
                    <button className="btn-inline btn-icon" onClick={handleAddClick}><AddIcon fontSize='inherit'/> New Resume</button>
                </div>
            </div>

            <div className="row">
                {/* Add Resume Card */}
                <div className="col col-12 col-md-6 col-lg-4 mb-3 d-none d-md-block" onClick={handleAddClick} style={{ cursor: 'pointer' }}>
                    <div className="resume-card br-10 h-100 d-flex flex-column align-items-center justify-content-center bg-xlblue">
                        <AddIcon />
                        <h5 className="title">Add New Resume</h5>
                    </div>
                </div>

                {resumes.map((resume, index) => (
                    <div className="col col-12 col-md-6 col-lg-4 mb-3" key={index} onClick={(e) => handleResumeClick(e, resume.id, 'edit')}>
                        <div className="resume-card br-10 h-100 d-flex flex-column">
                            <div className="card-header bg-dblue py-2 px-2 d-flex justify-content-between">
                                <div className="header-text">
                                    <h5 className="title text-white">{resume.name ? resume.name : defaultResumeTitle}</h5>
                                    <p className='sub sub-sm text-white'>Edited: {formatDate(resume.timestamp)}</p>
                                </div>
                                <button className="btn-delete-resume" onClick={(e) => handleResumeClick(e, resume.id, 'delete')}>
                                    <DeleteOutlineOutlinedIcon />
                                </button>
                            </div>
                            <div className="card-body p-2 bg-xlblue h-100">
                                {resume?.workNames?.length > 0 ? (
                                    <ul>
                                        {resume?.workNames?.map((role, index) => (
                                            <li key={index}>{role}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p className='text-xldblue mb-0'>No roles added</p>
                                )}
                            </div>
                            {confirmDeleteIndex === resume.id && (
                                <div className="delete-confirm-cont bg-red br-10">
                                    <div className="delete-confirm-inner bg-white br-5 h-100 d-flex flex-column justify-content-between">
                                        <div className="text-cont">
                                            <h5>Are you sure you want to delete?</h5>
                                            <p>{resume.title}</p>
                                        </div>
                                        <div className="btn-cont d-flex justify-content-between">
                                            <button className='btn btn-xlblue' onClick={(e) => handleCancelDelete(e)}>Cancel</button>
                                            <button className='btn btn-xlred' onClick={(e) => handleConfirmDelete(e)}>Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DashResumes;



// setResumes([
                //     {
                //         title: 'Resume 1',
                //         edited: '1/1/2021',
                //         roles: [
                //             'Role 1',
                //             'Role 2',
                //             'Role 3',
                //         ]
                //     },
                //     {
                //         title: 'Resume 2',
                //         edited: '1/1/2021',
                //         roles: [
                //             'Role 1',
                //             'Role 2',
                //             'Role 3',
                //         ]
                //     },
                //     {
                //         title: 'Resume 3',
                //         edited: '1/1/2021',
                //         roles: [
                //             'Role 1',
                //             'Role 2',
                //             'Role 3',
                //         ]
                //     }]
                // )
import React, { useState, useEffect} from 'react'
import useAxios from '../../utils/useAxios';

// import scss
import '../../assets/scss/components/account/resetPasswordForm.scss';

// import icons
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import CloseIcon from '@mui/icons-material/Close';

const ResetPasswordForm = () => {
    
    const [oldPassword, setOldPassword] = useState('**********');
    const [showFields, setShowFields] = useState(false);
    const [passwordTitle, setPasswordTitle] = useState('Password');
    const [resetPassword, setResetPassword] = useState(false);

    let api = useAxios()

    const handlePasswordEmailReset = (e) => {
        e.preventDefault();
        sendResetEmail();
    };

    const sendResetEmail = () => {
        api
        .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/resetPassword')
          .then(function(response){
            // console.log(response)
          })
          .catch(function (error) {
            console.log(error.message);
          });
        setResetPassword(true);
        return;
    }

    const handleResetPasswordClose = () => {
        setResetPassword(false);
    }

  return (
    <>
    <form className='reset-password-form gap-1 d-flex flex-column align-items-start'>
      <div className='input-item d-flex flex-row flex-wrap'>
          <label className='sub sub-sm text-dblue w-100' htmlFor="oldPassword">{passwordTitle}:</label>
          <input
              className='me-0 me-lg-2'
              disabled={!showFields}
              type="password"
              id="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
          />
          
          <button className='btn-inline btn-icon ps-0'  onClick={handlePasswordEmailReset}><LockOpenOutlinedIcon fontSize='inherit'/>Reset Password</button>
          
      </div>
      
    </form>
    <div className={`reset-password-confirmation-cont ${resetPassword == true ? 'active' : ''}`}>
      <div className='reset-password-confirmation mt-1 d-flex justify-content-between align-items-center'>
        <p className="mb-0">We've sent you an email with a link to reset your password</p>
        <button onClick={handleResetPasswordClose}><CloseIcon fontSize='inherit'/></button>
      </div>
    </div>
    
    
    {/* <div className="reset-password-modal">
        <div className="reset-password-modal-content">
            <h3>Reset Password</h3>
            <p>Enter your current password to reset your password.</p>
        </div>
    </div> */}
    </>
  );
};

export default ResetPasswordForm;

import React from 'react'
import CloseIcon from '@mui/icons-material/Close';

// import components
import AccountDropDown from './nav/AccountDropDown'

// import SCSS
import '../assets/scss/components/topbar.scss';

// import Images
import brLogoColor from '../assets/images/br-logo-color.svg';
import brLogoBw from '../assets/images/br-logo-bw.svg';



const TopBar = (props) => {
    let exitSignup = ''
    let logo = brLogoBw
    let bgColorClass = 'bg-black'
    let accountDropDown = <AccountDropDown />
    
    if(props.page === 'signup' || props.page === 'login' || props.page === 'resetPassword'){
        logo = brLogoColor
        bgColorClass = ''
        exitSignup = <a href='https://boostedresume.com' className="btn-close btn-exit-signup"><CloseIcon/></a>
        accountDropDown = ''
    }

    return (
        <section className={`section-top-bar ${bgColorClass}`}>
            <div className="logo-cont">
                <img className="top-bar-logo" src={logo} alt="boosted resume logo" />
            </div>
            <div className="btn-cont">
                {exitSignup}
                {accountDropDown}
            </div>
        </section>
        
    )
}

export default TopBar
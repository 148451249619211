import React, {useState} from 'react'

// import icons
import ConstructionIcon from '@mui/icons-material/Construction';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';

// import scss
import '../../assets/scss/components/resume/previewNav.scss';

const EditNav = ({editType, onChildStateChange}) => {
    const [currentEditType, setEditType] = useState(editType);

    const handleTabButtonClick = (type) => {
        setEditType(type);
        onChildStateChange(type);
    }

    return (
        <div className="preview-nav w-100 mt-2 bg-white br-10 mb-4">
            <div className="tab-cont d-flex align-items-center justify-content-center">
                <div className="tab-item tab-work-history px-2 py-1 active">
                    <button 
                        className='btn btn-icon btn-tab active'
                    ><RemoveRedEyeOutlinedIcon fontSize='inherit'/>Preview</button>
                </div>
                <div className="tab-item tab-job-description px-2 py-0 d-flex flex-column justify-content-center">
                    <span className="sub sub-sm text-xldblue">Coming Soon</span>
                    <p className="text-xldblue mb-0"><BookOutlinedIcon fontSize='inherit'/>Templates</p>
                </div>
            </div>
        </div>

    )
}

export default EditNav
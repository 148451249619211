import React, {useState, useRef, useEffect} from 'react'

// import components
import LoadingAnimation from './LoadingAnimation'

// import scss
import '../../assets/scss/components/resume/workHistoryModal.scss'

// import mui close icon
import CloseIcon from '@mui/icons-material/Close';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import SendIcon from '@mui/icons-material/Send';

const WorkHistoryModal = ({index, isOpen, closeModal, job, handleDescriptionChange, handleTitleChange, handleAIWriterClick, loading}) => {
    // console.log(job)
    const [jobTitle, setJobTitle] = useState(job.title)
    const [jobDescription, setJobDescription] = useState(job.description)
    const [jobReturns, setJobReturns] = useState(job.returns)
    const [editJobTitle, setEditJobTitle] = useState(false)
    // const [modalIndex, setModalIndex] = useState(index)
    let modalIndex = index

    const jobTitleInputRef = useRef(null);
    const jobDescriptionInputRef = useRef(null);
    
    useEffect(() => {
        setJobReturns(job.returns)
    }, [index, job])

    const handleJobTitleClick = (e) => {
        setEditJobTitle(true)
        const jobTitleInput = document.getElementById('modal-job-title')
        console.log(jobTitleInput)
        // Use a setTimeout to ensure that the input field is focused after rendering
        setTimeout(() => {
            if (jobTitleInputRef.current) {
                jobTitleInputRef.current.focus();
                jobTitleInputRef.current.select();
            }
        }, 50);
    }

    const handleBlur = () => {
        setEditJobTitle(false);
        setJobTitle(jobTitleInputRef.current.value);
        // callback function to set state in parent component
        handleTitleChange(modalIndex, jobTitleInputRef.current.value)
    };

    // handle enter key press on title change
    const handleTitleEnter = (e) => {
        if (e.key === 'Enter') {
            // Enter key was pressed, add your logic here
            setEditJobTitle(false);
            setJobTitle(jobTitleInputRef.current.value);
            e.preventDefault(); // Prevents the default Enter key behavior
        }
    };

    // handle enter key press on description change
    const handleDescriptionEnter = (e) => {
        
        if (e.key === 'Enter') {
            e.preventDefault()
            // Enter key was pressed, add your logic here
            setJobDescription(jobDescriptionInputRef.current.value);
            handleAIWriter(job.id, jobTitle, jobDescription)
        }
    };

    const handleInput = (e) => {
        // You can debounce this function if necessary to reduce the frequency of state updates
        setJobDescription(e.currentTarget.innerText);
    };

    const handleAIWriter = (id, title, description) => {
        console.log(id + ' ' + title + ' ' + description)
        handleAIWriterClick(id, title, description)
    }

    const handleCloseModal = () => {
        closeModal()
    }

    useEffect(() => {
        // callback function to set state in parent component
        handleDescriptionChange(modalIndex, jobDescription)
    }, [jobDescription, jobReturns])

    useEffect(() => {
        setTimeout(() => {
            document.body.scrollTop = document.body.scrollHeight;
            document.documentElement.scrollTop = document.documentElement.scrollHeight;
        }, 500);
    }, [jobReturns])

    useEffect(() => {
        if (jobDescriptionInputRef.current && !jobDescriptionInputRef.current.innerText) {
            jobDescriptionInputRef.current.innerText = jobDescription;
        }
    }, []);

    // remove styles when pasting
    document.addEventListener('copy', function(e) {
        const text_only = document.getSelection().toString();
        const clipdata = e.clipboardData || window.clipboardData;  
        clipdata.setData('text/plain', text_only);
        clipdata.setData('text/html', text_only);
        e.preventDefault();
    });


    return (
        <div className='work-history-modal-cont w-100 bg-noise'>
            <div className="header-cont bg-xlblue p-1 w-100  d-flex justify-content-between">
                
                    <div className={`job-title-input-cont w-100 ${editJobTitle ? 'd-block' : 'd-none'}`}>
                        <input 
                        className=''
                        ref={jobTitleInputRef}
                        id='modal-job-title' 
                        type="text" 
                        value={jobTitle ? jobTitle : 'Job Title'}
                        onChange={(e) => setJobTitle(e.target.value)}
                        onBlur={handleBlur}
                        onKeyDown={handleTitleEnter}
                        />
                    </div>
                    <div className={`job-title-cont align-items-center ${editJobTitle ? 'd-none' : 'd-flex'}`} onClick={(e) => handleJobTitleClick(e)}>
                        <h4 className='text-dblue mb-0'>{jobTitle}</h4>
                        <button className='btn-edit-title text-dblue bg-none'><CreateOutlinedIcon fontSize="medium"/></button>
                    </div>
                <button className='close-modal' onClick={handleCloseModal}><CloseIcon fontSize="medium"/></button>
            </div>
            <div className="return-cont">
                {jobReturns.map((ret, returnIndex) => {
                    let items = [];
                    if(ret !== null && (ret.response && ret.response.length > 0 && ret.response !== 'Error generating response')) {
                        if( ret.response.includes('-') && ret.response.indexOf('-') === 0 ){
                            items = ret.response.split('- ');
                            items.shift(); // Remove the first item, which is the empty string before the first ' - '
                        } else{
                            items[0] = ret.response;
                        }
                    } else{
                        items[0] = ret.response;
                    }
                    return (
                    <div className="return-item-cont px-1 w-100" key={returnIndex}>
                        <p className="sub sub-sm text-dblue">return</p>
                        <div className="cadby return-item d-flex justify-content-between align-items-center mb-1 bg-white br-5 p-1">
                            <ul>
                                {items.map((item, itemIndex) => (
                                    <li key={itemIndex}>{item}</li>
                                ))}
                            </ul>
                            {/* <p className="return-message text-black mb-0">{ret.response}</p> */}
                        </div>
                    </div>
                    )
                })}
                {loading ? <LoadingAnimation /> : ''}
            </div>
            <div className="description-cont bg-xlblue p-1 w-100">
                <p className="sub sub-sm text-dblue">description</p>
                <div className="description-item d-flex justify-content-between align-items-end mb-1 br-5 p-0">
                    {/* <textarea 
                    type='text'
                    className="description-input text-black w-100 br-5" 
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                    /> */}
                    <span 
                    role='textbox'
                    ref={jobDescriptionInputRef}
                    className="description-input text-black w-100 br-5 bg-white" 
                    value={jobDescription}
                    onInput={(e) => setJobDescription(e.target.innerText)}
                    contentEditable="true" 
                    onInput={handleInput}
                    onKeyDown={handleDescriptionEnter}
                    ></span>
                    <button 
                    className='bg-blue text-white br-5 ms-1 btn-modal-send d-flex align-items-center justify-content-center'
                    onClick={() => handleAIWriter(job.id, jobTitle, jobDescription)}
                    
                    >
                        <SendIcon className='' fontSize='inherit'/>
                    </button>
                </div>
                { loading ? <div className="hide-input"></div> : ''}
            </div>
        </div>
    )
}

export default WorkHistoryModal
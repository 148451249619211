import axios from 'axios'
import jwt_decode from "jwt-decode";
import dayjs from 'dayjs'
import { useContext } from 'react'
import AuthContext from '../context/AuthContext'


const baseURL = process.env.REACT_APP_BACKEND_DOMAIN


const useAxios = () => {
    const {authTokens, setUser, setAuthTokens} = useContext(AuthContext)

    const axiosInstance = axios.create({
        baseURL,
        headers:{Authorization: `${authTokens}`},
        withCredentials: true 
    });


    axiosInstance.interceptors.request.use(async req => {

        const user = jwt_decode(authTokens);  // Assuming the authTokens directly contains the JWT
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;  // Checking if token is expired
    
        if (!isExpired) return req;
    
        // Using the axios instance ensures withCredentials is set, so cookies are sent with the request
        const response = await axiosInstance.post(`${baseURL}token/`);
    
        if (response.data && response.data.token) {
            localStorage.setItem('authTokens', JSON.stringify(response.data.token));
            
            // Update the context with the new token
            setAuthTokens(response.data.token);
    
            // Optionally, update user info if needed
            setUser(jwt_decode(response.data.token));
    
            // Set the Authorization header for the current request
            req.headers.Authorization = `JWT ${response.data.token}`;
        }
    
        return req;
    });
    
    return axiosInstance;
}

export default useAxios;
import React from 'react'

// import components
import TopBar from '../components/TopBar'
import Header from '../components/account/Header'
import Tabs from '../components/account/Tabs'
import Footer from '../components/Footer'

const Account = (props) => {

    return (
        <section className="section-account">
            <TopBar />
            <Header />
            <Tabs />
            <Footer />
        </section>
    )
}

export default Account
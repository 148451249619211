import React, {useState, useEffect, useRef } from 'react'

// import Icons
import PersonIcon from '@mui/icons-material/Person';

const AccountDropDown = () => {

    // const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
  
    // const handleOptionClick = (option) => {
    //     setSelectedOption(option);
    //     setIsOpen(false);
    //     onSelect(option);
    // };
  
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        }
    };

    const handleLogout = (e) => {
        e.preventDefault();
        // Remove authentication tokens and user data from local storage
        localStorage.removeItem('authTokens'); // Change this to match your token key
        // Redirect the user to the login page (or any other desired route)
        window.location.href = '/login'; // Redirect to the login page
    }
    
    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
        document.removeEventListener('click', handleOutsideClick);
        };
    }, []);
  
    return (
      <div className="account-dropdown-cont" ref={dropdownRef}>
        <div className="dropdown-toggle" onClick={toggleDropdown}>
          <PersonIcon />
        </div>
        <ul className={`dropdown-options bg-white ${isOpen ? 'open' : ''}`}>
            <li className="dropdown-option">
                <a className="inline-link d-block" href="/account/information">Account Information</a>
            </li>
            <li className="dropdown-option">
                <a className="inline-link pt-1 d-block" href="/account/settings">Account Settings</a>
            </li>
            <li className="dropdown-option">
                <a className="inline-link pt-1 d-block" href="/account/billing">Billing</a>
            </li>
            <li className="dropdown-option">
                <a className="inline-link pt-1 d-block" href="/account/subscription">Subscription</a>
            </li>
            <li className="dropdown-option">
                <a className="inline-link pt-1 d-block" onClick={handleLogout} href="#">Logout</a>
            </li>

        </ul>
      </div>
    );

}

export default AccountDropDown
import React from 'react'
import TopBar from '../components/TopBar'
import LoginForm from '../components/LoginForm'

// import scss
import '../assets/scss/pages/signup.scss';

const Signup = () => {
    return (
        <div className="page-signup">
            <TopBar page='signup'/>
            <LoginForm />
        </div>
    )
}

export default Signup
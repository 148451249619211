import React, {useState} from 'react'

// import icons
import ConstructionIcon from '@mui/icons-material/Construction';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';

// import scss
import '../../assets/scss/components/resume/editNav.scss';

const EditNav = ({editType, onChildStateChange}) => {
    const [currentEditType, setEditType] = useState(editType);

    const handleTabButtonClick = (type) => {
        setEditType(type);
        onChildStateChange(type);
    }

    return (
        <div className="edit-nav w-100 mt-2 bg-xlblue br-10 mb-2 mb-lg-4">
            <div className="tab-cont d-flex align-items-center justify-content-center">
                <div className="tab-item tab-work-history px-lg-2 py-lg-1 active">
                    <button 
                        className={`btn btn-icon btn-tab ${
                            currentEditType === 'workHistory' ? 'active' : ''
                        }`}
                        onClick={() => handleTabButtonClick('workHistory')}
                    ><ConstructionIcon fontSize='inherit'/>Work History</button>
                </div>
                <div className="tab-item tab-job-description px-lg-2 py-lg-1">
                    <button 
                        className={`btn btn-icon btn-tab ${
                            currentEditType === 'jobDescription' ? 'active' : ''
                        }`}
                        onClick={() => handleTabButtonClick('jobDescription')}
                    >
                        <DriveFolderUploadOutlinedIcon fontSize='inherit'/>Job Description</button>
                </div>
            </div>
        </div>

    )
}

export default EditNav
import React, { useState, useEffect, useRef, createRef } from 'react'
import { v4 as uuidv4 } from 'uuid';

import useAxios from '../../utils/useAxios';
import '../../assets/scss/components/resume/workHistory.scss'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
// import { on } from 'npm/lib/npm';

// import components
import WorkHistoryModal from './WorkHistoryModal';
import ModalVerifyEmail from '../account/ModalVerifyEmail';

const WorkHistory = ({workHistory, onChildStateChange, activeIndex, handleActiveIndex, resumeId, handleLoading, loading}) => {
    let api = useAxios()
    // const [activeIndex, setActiveIndex] = useState(0);
    const [jobActiveIndex, setJobActiveIndex] = useState(activeIndex);
    const titleInputRef = useRef(null);
    // const [jobHistories, setJobHistories] = useState([
    //     { id: uuidv4(), title: '', description: '' },
    // ]);
    const [jobHistories, setJobHistories] = useState([]);
    const [confirmDeleteIndex, setConfirmDeleteIndex] = useState(-1);

    // const [resumeID, setResumeID] = useState(resumeId);
    // Declare descriptionTimeoutRef and titleTimeoutRef using useRef
    // const descriptionTimeoutRef = useRef(null);
    // const titleTimeoutRef = useRef(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
    const [isDesktop, setIsDesktop] = useState(true);
    const [modalIndex, setModalIndex] = useState(0);
    const [verifyEmailModalOpen, setVerifyEmailModalOpen] = useState(false);

    // Create a state to store refs
    const jobHistoryRefs = useRef([]);

    useEffect(() => {
        // Assign a ref for each job history item
        jobHistoryRefs.current = jobHistories.map((_, i) => jobHistoryRefs.current[i] || createRef());
    }, [jobHistories]);


    useEffect(() => {
        if (jobActiveIndex === jobHistories.length && jobHistories.length >0) {
            titleInputRef.current.focus();
        }
    }, [jobActiveIndex, jobHistories]);

    useEffect(() => {
        // Update the jobHistories state when the workHistory prop changes

        setJobHistories(workHistory);
    }, [workHistory]);

    useEffect(() => {
        const selectedWorkHistory = jobHistories[jobActiveIndex]
        setModalData({...selectedWorkHistory})
        
    }, [jobHistories, jobActiveIndex]);

    useEffect(() => {
        if (window.innerWidth > 991) {
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
    }, []);

    useEffect(() => {
        if( verifyEmailModalOpen ){
            document.body.classList.add('modal-open'); // add class to body for overflow hidden
        } else{
            document.body.classList.remove('modal-open'); // add class to body for overflow hidden
        }
    }, [verifyEmailModalOpen]);

    const updateMedia = () => {
        if (window.innerWidth > 991) {
            setIsDesktop(true);
        } else {
            setIsDesktop(false);
        }
    };
    window.addEventListener('resize', updateMedia);

    const closeModal = () => {
        setModalOpen(false);
        setModalData([]);
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    };

    const closeVerifyEmailModal = () => {
        setVerifyEmailModalOpen(false);
    };

    // useEffect(() => {
        // Here you can fetch job histories from an API or local storage
        // let resumeid = '1'
        // const fetchedJobHistories = api
        //     .get(process.env.REACT_APP_BACKEND_DOMAIN + 'api/content/' + resumeid)
        //     .then(function(response){
        //     })

        // setJobHistories(fetchedJobHistories);
    // }, []);

    


    // const workHistoryFocus = ({e, index, job}) => {
    //     if( isDesktop ){
    //         setJobActiveIndex(index);
    //         handleActiveIndex(index);
    //         let resumeItem = document.querySelector(`.work-history-return-cont[data-index="${index}"] .item-body`);
    //         if (resumeItem) {
    //             setTimeout(function(){
    //                 resumeItem.scrollTop = resumeItem.scrollHeight; // Scroll to the bottom
    //             }, 300)
    //         }
    //     } else {
    //         e.target.blur()
    //         setJobActiveIndex(index);
    //         setModalData(job)
    //         setModalOpen(true)
    //         setModalIndex(index)
    //         // body.classList.add('modal-open'); // add class to body for overflow hidden
    //     }
    // }



    const workHistoryFocus = ({e, index, job, type}) => {
        if (isDesktop) {
            
            // Check if the job history item is already active
            // only change active state if chevron is clicked
            if (jobActiveIndex === index && type === 'chevron') {
                setJobActiveIndex(-1);
                handleActiveIndex(-1);
            } else {
                setJobActiveIndex(index);
                handleActiveIndex(index);
            }

            // Use the ref to access the DOM node
            const resumeItem = jobHistoryRefs.current[index];
            if (resumeItem && resumeItem.current) {
                setTimeout(() => {
                    resumeItem.current.scrollTop = resumeItem.current.scrollHeight;
                }, 300);
            }
        } else {
            // Handle the non-desktop case
            setJobActiveIndex(index);
            setModalData(job);
            setModalOpen(true);
            setModalIndex(index);
        }
    };

    const handleCancelDelete = (e) => {
        setConfirmDeleteIndex(-1);
    }

    const handleConfirmDelete = (e) => {
    
        // Here you can add an API call to your backend to delete the job from the database
        api.post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/deleteContent/' + resumeId + '/' + confirmDeleteIndex)
        .then(response => {
            if (response.status === 200) {
                alert('Job deleted successfully.');
            }
        })
        .catch(error => {
            alert('Error deleting job.');
        });
    
        // Update the jobHistories state by filtering out the deleted job.
        setJobHistories(prev => prev.filter(job => job.id !== confirmDeleteIndex));
        setConfirmDeleteIndex(-1);
        e.stopPropagation();
    }

    const handleDescriptionChange = (index, value) => {
        setJobHistories((prevJobHistories) => {
            const updatedJobHistories = [...prevJobHistories];
            updatedJobHistories[index].description = value;
            // onChildStateChange(updatedJobHistories);
            return updatedJobHistories;
        });
    };

        
    const handleTitleChange = (index, value) => {
        setJobHistories((prevJobHistories) => {
            const updatedJobHistories = [...prevJobHistories];
            updatedJobHistories[index].title = value;
            onChildStateChange(updatedJobHistories);
            console.log(index, value)
            
            return updatedJobHistories;
        });
    };

    // const handleTitleChange = (index, newValue) => {
    //     setJobHistories(currentHistories => {
    //         console.log('Before update:', currentHistories[index].title);
    
    //         const newHistories = currentHistories.map((job, idx) =>
    //             idx === index ? { ...job, title: newValue } : job
    //         );
    
    //         console.log('After update:', newHistories[index].title);
    //         return newHistories;
    //     });
    // };

    //onChildStateChange(updatedJobHistories);

    // const handleDescriptionChange = (index, value) => {
    //     // Clear any existing timeout
    //     if (descriptionTimeoutRef.current) {
    //         clearTimeout(descriptionTimeoutRef.current);
    //     }
    
    //     // Set a new timeout to update the state after a delay
    //     descriptionTimeoutRef.current = setTimeout(() => {
    //         setJobHistories((prevJobHistories) => {
    //             const updatedJobHistories = [...prevJobHistories];
    //             updatedJobHistories[index].description = value;
    //             return updatedJobHistories;
    //         });
    //     }, 500); // Adjust the timeout duration as needed
    // };
    
    // const handleTitleChange = (index, value) => {
    //     // Clear any existing timeout
    //     if (titleTimeoutRef.current) {
    //         clearTimeout(titleTimeoutRef.current);
    //     }
    
    //     // Set a new timeout to update the state after a delay
    //     titleTimeoutRef.current = setTimeout(() => {
    //         setJobHistories((prevJobHistories) => {
    //             const updatedJobHistories = [...prevJobHistories];
    //             updatedJobHistories[index].title = value;
    //             return updatedJobHistories;
    //         });
    //     }, 500); // Adjust the timeout duration as needed
    // };

    // useEffect((index, value) => {
    //     // const timeOutId = setTimeout(() => setDisplayMessage(query), 500);
    //     const timeOutId = setTimeout(() => setJobHistories((prevJobHistories) => {
    //         const updatedJobHistories = [...prevJobHistories];
    //         updatedJobHistories[index].description = value;
    //         onChildStateChange(updatedJobHistories);
    //     }), 500);
    //     return () => clearTimeout(timeOutId);
    // }, [jobHistories]);

    

    const handleAIWriterClick = async(id, title, description, index) => {
        // You can make the backend request here
        const formData = new FormData();
        
        formData.append('prompt', title);
        formData.append('context', description);
        
        // let token = JSON.parse(localStorage.getItem('authTokens'));
        handleLoading(true)
        api
          .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/content/' + resumeId + '/' + id, formData)
          .then(function(response){

            setJobHistories((prevJobHistories) => {
                // handleLoading(false)
                // const updatedJobHistories = [...prevJobHistories];
                // const targetJob = updatedJobHistories.find(job => job.id === id);
                // if (targetJob) {
                //     targetJob.returns.push(response.data.response)
                // }
                // // onChildStateChange(updatedJobHistories); // Notify the parent component of the change
                // return updatedJobHistories;

                handleLoading(false);
                const updatedJobHistories = [...prevJobHistories];
                const targetJobIndex = updatedJobHistories.findIndex((job) => job.id === id);
                if (targetJobIndex !== -1) {
                    // Create a new array with the updated returns
                    const newReturn = {
                        response: response.data.response,
                        timestamp: response.data.timestamp
                    }
                    const updatedReturns = [...updatedJobHistories[targetJobIndex].returns, newReturn];
                    // updatedJobHistories[targetJobIndex].returns['response'] = response.data.response;
                    // updatedJobHistories[targetJobIndex].returns['timestamp'] = response.data.timestamp;
                    // Update the target job with the new returns
                    updatedJobHistories[targetJobIndex].returns = updatedReturns;
                }
                // Notify the parent component of the change (if needed)
                // onChildStateChange(updatedJobHistories);

                return updatedJobHistories;
            });

            // setModalData(jobHistories[index])
        
          })
          .catch(function (error) {
            // if user has not verified their account via email
            if( error.response.status === 420 ){
                handleLoading(false);
                setVerifyEmailModalOpen(true);
            }
            //window.location = '/login'
          });

    }

    const handleSaveClick = async(id, title, description, index) => {
        // You can make the backend request here
        const formData = new FormData();
        
        formData.append('prompt', title);
        formData.append('context', description);
        
        // let token = JSON.parse(localStorage.getItem('authTokens'));
        api
          .post(`${process.env.REACT_APP_BACKEND_DOMAIN}api/updateContent/${resumeId}/${id}`, formData)
          .then(function(response){

            setJobHistories((prevJobHistories) => {
                // handleLoading(false)
                // const updatedJobHistories = [...prevJobHistories];
                // const targetJob = updatedJobHistories.find(job => job.id === id);
                // if (targetJob) {
                //     targetJob.returns.push(response.data.response)
                // }
                // // onChildStateChange(updatedJobHistories); // Notify the parent component of the change
                // return updatedJobHistories;

           
                const updatedJobHistories = [...prevJobHistories];
                const targetJobIndex = updatedJobHistories.findIndex((job) => job.id === id);
                if (targetJobIndex !== -1) {
                    // Create a new array with the updated returns
                    const updatedReturns = [...updatedJobHistories[targetJobIndex].returns, response.data.response];
                    // Update the target job with the new returns
                    updatedJobHistories[targetJobIndex].returns = updatedReturns;
                }
                // Notify the parent component of the change (if needed)
                // onChildStateChange(updatedJobHistories);
                return updatedJobHistories;
            });

            // setModalData(jobHistories[index])

        
          })
          .catch(function (error) {
            // if user has not verified their account via email
            if( error.response.status === 420 ){

                setVerifyEmailModalOpen(true);
            }
            //window.location = '/login'
          });

    }

    // const addNewJobHistory = () => {
    //     setJobHistories(prev => [...prev, { id: uuidv4(), title: '', description: '' }]);
    //     setActiveIndex(jobHistories.length);
    //     setTimeout(() => {
    //         titleInputRef.current.focus(); // Focus on the title input after a small delay
    //     }, 0);
    //     onChildStateChange(prev);
    // }



    const addNewJobHistory = () => {
        setJobHistories(prev => {
            const updatedHistories = [...prev, { 
                id: uuidv4(), 
                title: 'Job Title', 
                description: '',
                returns: []
            }];
            onChildStateChange(updatedHistories); // Pass the updated state to the parent
            setJobActiveIndex(updatedHistories.length);
            setTimeout(() => {
                titleInputRef.current.focus(); // Focus on the title input after a small delay
            }, 0);
            return updatedHistories; // Return the new state value
        });
    };

    const deleteJobHistory = (idToDelete) => {
        // setJobHistories(prev => prev.filter(job => job.id !== idToDelete));
        setConfirmDeleteIndex(idToDelete);
    }


    return (
        <div className="work-history-cont">
            {jobHistories.map((job, index) => (
                <div 
                className={`work-history-item br-10 p-md-1 mb-2 ${jobActiveIndex === index ? 'active' : ''}`} 
                key={job.id}
                >
                    <div className={`item-header d-flex justify-content-between ${confirmDeleteIndex === job.id && 'd-none' }`}>
                        <input type="text" 
                        ref={index + 1 === jobHistories.length ? titleInputRef : null}
                        className="work-history-input item-title br-5 w-100 me-2" 
                        placeholder='Job Title' 
                        onFocus={(e) => workHistoryFocus({e, index: index, job: job, type: 'title'})}
                        onBlur={ () => onChildStateChange(jobHistories)}
                        value={job.title}
                        onChange={(e) => handleTitleChange(index, e.target.value)}
                        />
                        <div className="btn-cont">
                            <button 
                            className="btn-resume-item btn-delete" 
                            onClick={() => deleteJobHistory(job.id)}
                            >
                                <DeleteOutlinedIcon fontSize='medium'/>
                            </button>
                            <button 
                                className="d-none d-lg-block btn-resume-item btn-chevron"
                                // onFocus={(e) => workHistoryFocus(e, index, job)}
                                // onClick={(e) => workHistoryFocus(e.currentTarget, index, job)}
                                onClick={(e) => workHistoryFocus({e, index: index, job: job, type: 'chevron'})}
                            >
                                <ArrowForwardIosOutlinedIcon fontSize='medium'/>
                            </button>
                        </div>
                    </div>
                    <div className="item-body d-flex flex-column">
                        <div className="input-cont d-flex flex-column my-2 w-100">
                            <label htmlFor="description" className="form-label sub sub-sm text-dblue">Description</label>
                            <textarea
                                rows="5"
                                label="description"
                                className="work-history-input description-input w-100 br-5"
                                // value={job.description}
                                defaultValue={job.description}
                                onChange={(e) =>
                                    handleDescriptionChange(index, e.target.value)
                                }
                            />
                        </div>
                        <div className="btn-cont d-flex justify-content-end">
                        <button className="btn btn-blue d-inline-flex" style={{ marginRight: '10px' }}
                                onClick={() => handleSaveClick(job.id, job.title, job.description, index)}>
                            Save
                        </button>
                        <button className="btn btn-blue btn-accent-yellow d-inline-flex"
                                onClick={() => handleAIWriterClick(job.id, job.title, job.description, index)}>
                            Generate tailored description
                        </button>
                        </div>

                    </div>

                    {confirmDeleteIndex === job.id && (
                        <div className="delete-confirm-cont bg-red br-10">
                            <div className="delete-confirm-inner bg-white br-5 h-100 d-flex flex-column justify-content-between">
                                <div className="text-cont">
                                    <h5>Are you sure you want to delete?</h5>
                                    <p>{job.title}</p>
                                </div>
                                <div className="btn-cont d-flex justify-content-between">
                                    <button className='btn btn-xlblue' onClick={(e) => handleCancelDelete(e)}>Cancel</button>
                                    <button className='btn btn-xlred' onClick={(e) => handleConfirmDelete(e)}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                    
            ))}
            <button onClick={addNewJobHistory} className="btn btn-blue mt-2">
                Add New Job
            </button>
            {modalOpen && 
                <WorkHistoryModal 
                    index={modalIndex} 
                    isOpen={modalOpen} 
                    closeModal={closeModal} 
                    job={modalData} 
                    handleDescriptionChange={handleDescriptionChange}
                    handleTitleChange={handleTitleChange}
                    handleAIWriterClick={handleAIWriterClick}
                    loading={loading}
                /> 
            }
            {verifyEmailModalOpen && <ModalVerifyEmail closeVerifyEmailModal={closeVerifyEmailModal} />}

        </div>
    )
}

export default WorkHistory;
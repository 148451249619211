// import logo from './logo.svg';
import './assets/scss/app.scss';

import React, {useState} from "react";
// import jwt_decode from "jwt-decode";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Home from './pages/Home'
import Signup from './pages/Signup'
import Dashboard from './pages/Dashboard';
import Account from './pages/Account';
import Resume from './pages/Resume';
import PrivateRoutes from './utils/PrivateRoutes'
import Login from './pages/Login';
import { AuthProvider } from './context/AuthContext'
import ResetPassword from './pages/ResetPassword';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';

function App() {
  return (
    <Router>
      <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/account/information" element={<Account page='information' />} />
          <Route path="/account/settings" element={<Account page='settings' />} />
          <Route path="/account/billing" element={<Account page='billing' />} />
          <Route path="/account/subscription" element={<Account page='subscription' />} />
        </Route>
        
      </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;

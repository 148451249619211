import React, { useState, useContext } from 'react';
import axios from "axios";
// import AuthContext from '../context/AuthContext';
// import jwt_decode from "jwt-decode";

// import scss
import '../assets/scss/components/signupForm.scss';

const SignupForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [error, setError] = useState('');  // to store error message
  
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const createUser = () => {
        axios.post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/signup', {
            email: email,
            password: password
        },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(response => {
            if(response.status === 200){
                localStorage.setItem('authTokens', JSON.stringify(response.data.token));
                window.location = '/dashboard';
            }else{
                setError('Something went wrong!');  // Setting error message on non-200 status
            }
        })
        .catch(error => {
            console.log(error.message);
            setError('Invalid email or password.');  // Setting error message on exception
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        createUser();
    };
    
    return (
        <section className="section-signup">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col col-12 col-md-6 col-lg-5 col-xl-4">
                        <div className="img-cont w-100 text-center mb-2">
                            <svg width="28" height="64" viewBox="0 0 28 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M24.958 20.6208C22.5677 23.7143 21.6286 25.5215 20.5507 29.6971C20.513 29.843 20.3829 29.9471 20.2332 29.9471H7.7708C7.62108 29.9471 7.491 29.843 7.45336 29.6971C6.37509 25.5203 5.43569 23.7131 3.04379 20.618C1.5606 22.7777 0.691895 25.3974 0.691895 28.2212C0.691895 35.6165 13.9999 63.6229 13.9999 63.6229C13.9999 63.6229 27.308 35.6165 27.308 28.2212C27.308 25.3986 26.44 22.78 24.958 20.6208Z" fill="#FFE602"/>
                                <path d="M21.34 27.0055C21.0697 27.7969 20.8143 28.6758 20.5507 29.6971C20.513 29.843 20.3829 29.9471 20.2332 29.9471H7.7708C7.62108 29.9471 7.491 29.843 7.45336 29.6971C7.18924 28.674 6.93346 27.7939 6.66263 27.0014C5.98606 28.2196 5.60046 29.6237 5.60046 31.1183C5.60046 35.7861 14.0002 53.4631 14.0002 53.4631C14.0002 53.4631 22.3999 35.7861 22.3999 31.1183C22.3999 29.6253 22.0152 28.2227 21.34 27.0055Z" fill="#FFF6A3"/>
                                <path d="M7.90367 31.1826C7.90367 31.0006 8.05035 30.853 8.2313 30.853H19.7691C19.9501 30.853 20.0967 31.0006 20.0967 31.1826C20.0967 31.1826 19.8028 32.502 19.7691 32.6579C19.7354 32.8138 19.6224 32.9875 19.4415 32.9875H8.5571C8.37615 32.9875 8.27211 32.8092 8.22946 32.6579C8.18681 32.5066 7.90367 31.1826 7.90367 31.1826Z" fill="black"/>
                                <path d="M14.6017 45.0083C14.374 45.5387 13.6265 45.5387 13.3987 45.0083L9.0202 34.8133C8.83326 34.378 9.15043 33.8924 9.62171 33.8924H18.3788C18.85 33.8924 19.1672 34.378 18.9803 34.8133L14.6017 45.0083Z" fill="black"/>
                                <path d="M25.547 19.8693C22.714 23.4463 21.713 25.1946 20.5507 29.6971C20.513 29.843 20.3829 29.9471 20.2332 29.9471H7.7708C7.62108 29.9471 7.491 29.843 7.45336 29.6971C6.29101 25.1946 5.29004 23.4463 2.457 19.8693C2.37976 19.7718 2.35895 19.6471 2.41676 19.5368C3.34423 17.7669 10.7277 4.49583 12.9636 0.479478C13.1305 0.179655 13.5798 0.31236 13.5716 0.655917C13.4877 4.18545 13.2574 13.7156 13.1605 16.1859C13.1564 16.2914 13.1008 16.3802 13.016 16.4425C10.9034 17.9921 11.4829 21.0639 14.002 21.0639C16.5211 21.0639 17.1006 17.9921 14.988 16.4425C14.9032 16.3802 14.8476 16.2914 14.8435 16.1859C14.7466 13.7156 14.5163 4.18545 14.4324 0.655917C14.4242 0.31236 14.8735 0.179655 15.0404 0.479478C17.2763 4.49583 24.6598 17.7669 25.5873 19.5368C25.6451 19.6471 25.6243 19.7718 25.547 19.8693Z" fill="black"/>
                            </svg>
                        </div>
                        <h1 className="text-center mb-3">Sign Up</h1>
                        <div className="card p-3 bg-xlblue rounded-2">
                            <div className="card-body">
                                <form action="" className="form-signup d-flex flex-column align-items-center" onSubmit={handleSubmit}>
                                    <div className="form-group d-flex flex-column w-100 mb-1">
                                        <label htmlFor="" className="form-label"><span className="sub text-dblue">Email</span></label>
                                        <input
                                            type="email"
                                            id="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            className="br-5"
                                            name='email'
                                            required
                                        />
                                    </div>
                                    <div className="form-group d-flex flex-column mb-1 w-100">
                                        <label htmlFor="" className="form-label"><span className="sub text-dblue">Password</span></label>
                                            <input
                                                type="password"
                                                id="password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                className='br-5'
                                                name='password'
                                                required
                                            />
                                    </div>
                                    <div className="form-group d-flex flex-row mb-1 align-items-start mb-2 w-100">
                                        <input
                                            name="tos"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                            required
                                        />
                                        <label htmlFor="tos" className="ps-1 tos"><span className='sub sub-sm text-dblue'>Agree to Boosted Resume <a href='/terms-and-conditions'>Terms Of Service</a> and <a href='/privacy-policy'>Privacy Policy</a></span></label>
                                        
                                        
                                    </div>
                                    <button className='btn btn-primary' type="submit">Sign Up</button>
                                </form>
                                {error && <p style={{color: 'red', opacity: 0.8}}>{error}</p>}

                            </div>
                        </div>

                        <div className="login">
                            <p className="text-center mt-3">Already have an account? <a href="/login">Login</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SignupForm
import React, { useState, useEffect} from 'react'
import useAxios from '../../utils/useAxios';
// import scss
import '../../assets/scss/components/account/changeEmailForm.scss';

// import icons
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

const ChangeEmailForm = () => {
    ////////////////////////////////////
    // states
    const [email, setEmail] = useState('');
    const [editEmail, setEditEmail] = useState(false);

    let api = useAxios()

    ////////////////////////////////////
    // handlers
    const handleSubmit = (e) => {
        e.preventDefault();
        setEditEmail(true)
    };

    const handleCancel = () => {
        setEditEmail(false)
    }

    const loadInfo = () => {
        api
          .post(process.env.REACT_APP_BACKEND_DOMAIN + 'api/getRegisterInfo')
          .then(function(response){
            setEmail(response.data.data)
          })
          .catch(function (error) {
            console.log(error.message);
          });
    }

    // Using useEffect to call the function once the component is mounted
    useEffect(() => {
        loadInfo();
    }, []);

    return (
        <form className='change-email-form gap-1 d-flex flex-column align-items-start mb-1' onSubmit={handleSubmit}>
            <div className='input-item d-flex flex-row flex-wrap'>
                <label className='sub sub-sm text-dblue w-100' htmlFor="email">Email:</label>
                <input
                    className='me-0 me-lg-2'
                    disabled={!editEmail}
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={email}
                    required
                />
            </div>
            
            {editEmail && (
                <div className="btn-cont d-flex justify-content-between">
                    <button className="btn btn-xlred" onClick={handleCancel}>Cancel</button>
                    <button className="btn btn-primary" onClick={handleSubmit}>Save</button>
                </div>
                
            )}
        </form>
    )
}

export default ChangeEmailForm